import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

const StripeWrapper = ({ children }) => {
  const [ stripe, setStripe ] = useState(null)

  useEffect(() => {
    // for SSR
    if (typeof window == 'undefined') return

    // for browser
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.STRIPE_API))
    } else {
      const stripeScript = document.querySelector('#stripe-js')
      stripeScript.onload = () => {
        setStripe(window.Stripe(process.env.STRIPE_API))
      }
    }
  }, []) // <-- passing in an empty array since I only want to run this hook once

  return (
    <StripeProvider stripe={stripe}>
      {children}
    </StripeProvider>
  )
}

// import { Elements, StripeProvider } from 'gatsby-plugin-stripe';
import { Elements, StripeProvider } from 'react-stripe-elements';

import CheckoutForm from '../components/checkout-form/checkoutForm.js';

const Invoice = ({ data }) => (
  <Layout>
    <Head pageTitle={data.invoiceJson.title} />
    <Box>
      <StripeWrapper>
        <div className="example">
          <h1>Invoice</h1>
          <Elements>
            <CheckoutForm />
          </Elements>
        </div>
      </StripeWrapper>
    </Box>
  </Layout>
);

// const Invoice = ({ data }) => (
//   <Layout>
//     <Head pageTitle={data.invoiceJson.title} />
//     <Box>
//       <StripeProvider apiKey={process.env.STRIPE_API}>
//         <div className="example">
//           <h1>Invoice</h1>
//           <Elements>
//             <CheckoutForm />
//           </Elements>
//         </div>
//       </StripeProvider>
//     </Box>
//   </Layout>
// );

Invoice.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Invoice;

export const query = graphql`
  query InvoiceQuery {
    invoiceJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
